// ** Logo
import logo from '@src/assets/images/logo/logo_150.png';

const SpinnerComponent = () =>
    <div className='fallback-spinner fullpage-loader vh-100'>
        <img className='fallback-logo' src={ logo } alt='logo' />
        <div className='loading-fullpage'>
            <div className='effect-1 effects'></div>
            <div className='effect-2 effects'></div>
            <div className='effect-3 effects'></div>
        </div>
    </div>;


export default SpinnerComponent;
