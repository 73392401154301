import { SWRConfig } from 'swr';
import ReactDOM from 'react-dom';
import { Suspense, lazy } from 'react';

import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store.ts';

import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';

import './@core/components/ripple-button';
import Spinner from './@core/components/spinner/Fallback-spinner';

import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';
import '@styles/react/libs/toastify/toastify.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
    <Provider store={ store }>
        <Suspense fallback={ <Spinner /> }>
            <ThemeContext>
                <SWRConfig value={ { revalidateOnFocus: false, errorRetryCount: 0, errorRetryInterval: 0, shouldRetryOnError: false } }>
                    <IntlProviderWrapper>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </IntlProviderWrapper>
                </SWRConfig>
            </ThemeContext>
        </Suspense>
    </Provider>,
    document.getElementById('root'),
);