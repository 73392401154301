import themeConfig from '@configs/themeConfig';
import { ActionTypes } from '@src/utility/interfaces/redux/ActionTypes';

const initialMenuCollapsed = () => {
    const item = window.localStorage.getItem('menuCollapsed');
    return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const initialLayoutSkin = () => {
    const item = window.localStorage.getItem('skin');
    return item ? JSON.parse(item) : themeConfig.layout.skin;
};

const initialState = {
    skin: initialLayoutSkin(),
    isRTL: themeConfig.layout.isRTL,
    menuCollapsed: initialMenuCollapsed(),
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
};

const layoutReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
    case 'HANDLE_CONTENT_WIDTH':
        return { ...state, contentWidth: action.value };
    case 'HANDLE_MENU_COLLAPSED':
        window.localStorage.setItem('menuCollapsed', JSON.stringify(action.value));
        return { ...state, menuCollapsed: action.value };
    case 'HANDLE_MENU_HIDDEN':
        return { ...state, menuHidden: action.value };
    case 'HANDLE_SKIN':
        return { ...state, skin: action.value };
    default:
        return state;
    }
    /*     case 'HANDLE_RTL':
            return { ...state, isRTL: action.value }; */
};

export default layoutReducer;
