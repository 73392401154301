import createDebounce from 'redux-debounced';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from '../reducers/rootReducer';
import { ActionTypes } from '@src/utility/interfaces/redux/ActionTypes';

const middleware = [ thunk, createDebounce() ];

/* @ts-ignore */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<AppStore, Record<string, never>, ActionTypes>;

export { store };